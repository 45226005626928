import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import HttpStatus from 'http-status-codes';
import isEmpty from 'lodash/isEmpty';

import OtpModal from '@/components/otp/components/otp-modal';
import FormWrapperInstance from '@/core/fields/form-wrapper/form-wrapper';
import { OtpStatus } from '@/components/otp/constants';
import User from '@/store/models/user';
import UserAuth from '@/store/models/userAuth';
import { i18n } from '@/core/i18n';
import { IdentityService } from '@/services/identity-service';
import { getToken } from '@/utilities/grecaptcha';
import { RecaptchaAction } from '@/constants/recaptcha-action';
import Platform from '@/mixins/platform';
import EventId from '@/services/event-id';
import { ServerEnvironment } from '@/constants/server-environment';
import {
    matchCountryByPrefix,
    matchCountryByLocalName
} from '@/core/countries/helpers/country';
import PasswordNotification from '@/components/password-notification/';

@Component({
    data() {
        return {
            user: null,
            email: null,
            error: {},
            userAuth: {},
            loading: true,
            processing: false,
            countries: [],
            isNativeApp: false,
            isInvalid: false,
            mailSentEnabled: false
        };
    },
    components: {
        OtpModal,
        PasswordNotification
    }
})
export default class ResetPassword extends mixins(
    i18n.Mixins.Culture,
    Platform
) {
    @Ref() form!: InstanceType<typeof FormWrapperInstance>;

    @Prop(Number)
    serverEnvironment: ServerEnvironment;

    user;
    email: string;
    userAuth;
    error;
    loading: boolean;
    processing: boolean;
    isNativeApp: boolean;
    countries: Array<any>;
    mailSentEnabled: boolean;
    createStatus: OtpStatus = OtpStatus.None;
    isInvalid: boolean;
    prefix: string;

    async mounted() {
        this.loading = true;

        this.error = {};

        this.user = new User();
        this.user.countryCode = Vue.$localStorage.getItem('countryCode') === 'null' ? null : Vue.$localStorage.getItem('countryCode');
        this.user.mobileNumber = this.$route.params.mobileNumber
            ? this.$route.params.mobileNumber
            : Vue.$sessionStorage.getItem('mobileNumber');

        this.userAuth = new UserAuth();

        this.countries = Vue.$localStorage.getJsonItem('countriesPrefix') || [];
        this.isNativeApp = Vue.$localStorage.getJsonItem('isNativeApp');

        this.loading = false;
    }

    customFilter(item, queryText, itemText) {
        const localizedCountryName = this.$t(`countries:${item.code}.name`);

        return matchCountryByLocalName(localizedCountryName, queryText) ||
            matchCountryByPrefix(item, queryText);
    }

    get otpMobile() {
        if (this.user?.mobileNumber) {
            return `${this.user.mobilePrefix} ${this.user.mobileNumber}`;
        }

        return '';
    }

    @Watch('user.countryCode')
    watchCountryCode(value) {
        Vue.$geoLocator.setCountryCode(this.user.countryCode);
    }

    showCookieBlockedAlert() {
        return !window.navigator.cookieEnabled;
    }

    closeModal() {
        this.mailSentEnabled = false;
    }

    hasServerError() {
        return !isEmpty(this.error);
    }

    getErrorText() {
        if (this.error.eventId === EventId.InvalidUsernamePassword) {
            return this.$t('login:wrongCredentials');
        }
        if (this.error.eventId === EventId.AccountsLocked) {
            return this.$t('login:accountLockedError');
        }
        if (this.error.status === HttpStatus.TOO_MANY_REQUESTS) {
            return this.$t('common:otp.maxTries');
        }
        if (this.error.status === HttpStatus.CONFLICT) {
            return this.$t('login:passwordResetSessionExistsError');
        }

        return this.$t('login:generalErrorMessage');
    }

    getSignupLink() {
        const registerUrl = config.registerUrl || 'https://app.vivawallet.com/register/';

        return `${registerUrl}?lang=${this.getLang()}`;
    }

    async submit() {
        const formValid = await this.form?.validate();

        if (!formValid) {
            return;
        }

        this.error = {};
        this.processing = true;

        const tokenResult = await getToken(
            config.googleRecaptchaSiteKeyId,
            RecaptchaAction.ResetPasswordToken
        );

        if (!tokenResult.success) {
            this.processing = false;

            return;
        }

        const result = await IdentityService.resetPasswordToken({
            token: tokenResult.data.token,
            username: this.user.mobileNumber,
            countryCode: this.user.countryCode
        });

        if (!result.success) {
            this.processing = false;
            this.error = result.data;

            return;
        }

        this.email = result.data.email;
        this.processing = false;
        this.mailSentEnabled = true;
    }
}
