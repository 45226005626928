import { Component, Prop, Ref, Vue } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import HttpStatus from 'http-status-codes';
import isEmpty from 'lodash/isEmpty';
import dayjs from 'dayjs';

import OtpModal from '@/components/otp/components/otp-modal';
import { i18n } from '@/core/i18n';
import FormWrapperInstance from '@/core/fields/form-wrapper/form-wrapper';
import UserAuth from '@/store/models/userAuth';
import { getToken } from '@/utilities/grecaptcha';
import { RecaptchaAction } from '@/constants/recaptcha-action';
import { IdentityService } from '@/services/identity-service';
import MonitorRule from '@/components/monitor-rule';
import Platform from '@/mixins/platform';
import { OtpStatus } from '@/components/otp/constants';
import EventId from '@/services/event-id';
import { ServerEnvironment } from '@/constants/server-environment';
import { VerificationTokenInfo } from '@/interfaces';
import { getDeviceToken } from '@/core/iovation/utilities';

@Component({
    data() {
        return {
            verificationInfo: null,
            intent: null,
            error: {},
            userAuth: {},
            countries: [],
            returnUrl: '',
            loading: true,
            processing: false,
            otpLimitReached: false,
            isNativeApp: false,
            isInvalid: false,
            trackStatus: false,
            passwordRules: {
                minLength: false,
                numbers: false,
                letters: false,
                symbols: false
            },
            failModalOpened: false,
            successModalOpened: false
        };
    },
    components: {
        OtpModal,
        MonitorRule
    }
    })
export default class NewPassword extends mixins(
        i18n.Mixins.Culture,
        Platform
    ) {
    @Ref() form!: InstanceType<typeof FormWrapperInstance>;

    @Prop(Number)
    serverEnvironment: ServerEnvironment;

    @Prop()
    verificationToken!: string;

    verificationInfo : VerificationTokenInfo;
    userAuth;
    error;
    isNativeApp: boolean;
    returnUrl: string;
    isInvalid: boolean;
    loading: boolean;
    processing: boolean;
    countries: Array<any>;
    trackStatus: boolean;
    passwordRules;
    otpLimitReached: boolean;
    failModalOpened: boolean;
    successModalOpened: boolean;
    otpEnabled: boolean = false;
    createStatus: OtpStatus = OtpStatus.None;
    verificationTokenValid: boolean;

    async mounted() {
        this.loading = true;

        this.error = {};

        if (!this.verificationToken) {
            this.verificationTokenValid = false;

            return;
        }

        const { success, data } = await IdentityService.getResetPasswordTokenInfo(this.verificationToken);

        this.verificationInfo = data;

        this.verificationTokenValid = success;

        this.error = {};
        this.countries = Vue.$localStorage.getJsonItem('countriesPrefix') || [];
        this.returnUrl = Vue.$localStorage.getJsonItem('returnUrl') || '';
        this.isNativeApp = Vue.$localStorage.getJsonItem('isNativeApp');

        this.loading = false;
    }

    get validationRules() {
        return this.passwordRules.minLength &&
            this.passwordRules.numbers &&
            this.passwordRules.letters &&
            this.passwordRules.symbols;
    }

    get prefix() {
        if (!this.verificationInfo?.countryCode) {
            return '';
        }

        const userCountry = this.countries.find((country) => country.code === this.verificationInfo.countryCode);

        return userCountry ? userCountry.prefix : '';
    }

    get otpMobile() {
        return this.verificationInfo?.userName;
    }

    get isProductionServerEnvironment() {
        return this.serverEnvironment === ServerEnvironment.Production;
    }

    hasServerError() {
        return !isEmpty(this.error) && this.error.eventId !== EventId.SecurityCreateOtpConflict;
    }

    getErrorText() {
        return this.otpLimitReached ? this.$t('common:otp.maxTries') : this.$t('login:generalErrorMessage');
    }

    getSignupLink() {
        const registerUrl = config.registerUrl || 'https://app.vivawallet.com/register/';

        return `${registerUrl}?lang=${this.getLang()}`;
    }

    goToLogin() {
        this.$router.push({ name: 'login' });
    }

    goToReset() {
        this.$router.push({ name: 'reset-password' });
    }

    closeModal() {
        this.otpEnabled = false;
    }

    async submit() {
        this.trackStatus = true;

        const formValid = await this.form?.validate();

        if (!this.validationRules) {
            return;
        }

        if (!formValid) {
            return;
        }

        this.verificationInfo.requestTimestamp = dayjs(new Date()).valueOf();

        const tokenResult = await getToken(config.googleRecaptchaSiteKeyId);

        if (!tokenResult.success) {
            this.createStatus = OtpStatus.None;
            this.processing = false;

            return;
        }

        this.error = {};

        this.processing = true;

        const otpResult = await IdentityService.requestOtp({
            username: this.verificationInfo.userName,
            countryCode: this.verificationInfo.countryCode,
            language: this.getLang(),
            intent: this.verificationInfo.intent,
            token: tokenResult.data.token
        });

        if (!otpResult.success) {
            this.processing = false;
            this.error = otpResult.data;
            this.otpEnabled = this.error.status === HttpStatus.CONFLICT;

            return;
        }
        this.processing = false;
        this.otpEnabled = true;
    }

    async handleOtpValidation(otp: string) {
        if (!otp) {
            return;
        }

        this.userAuth.otp = otp;
        this.userAuth.receivedOtp = true;
        this.userAuth.otpMobile = this.verificationInfo.userName;

        await UserAuth.create({ data: this.userAuth });

        const tokenResult = await getToken(
            config.googleRecaptchaSiteKeyId,
            RecaptchaAction.ResetPassword
        );

        if (!tokenResult.success) {
            this.createStatus = OtpStatus.None;
            this.processing = false;

            return;
        }

        const result = await IdentityService.resetPassword({
            token: tokenResult.data.token,
            deviceToken: await getDeviceToken(),
            userName: this.verificationInfo.userName,
            password: this.verificationInfo.password,
            countryCode: this.verificationInfo.countryCode,
            oneTimePassword: this.userAuth.otp,
            verificationToken: this.verificationToken
        });

        if (!result.success) {
            this.processing = false;

            if (result.data.eventId === EventId.SecurityValidateOtpInvalid) {
                this.otpEnabled = true;
                this.isInvalid = true;
                this.createStatus = OtpStatus.InvalidOtp;
            }

            if (result.data.eventId === EventId.AccountsLocked) {
                this.error = result.data;
                this.closeModal();
            }

            if (result.data.status === HttpStatus.NOT_FOUND
                 && result.data.eventId !== EventId.SecurityValidateOtpInvalid) {
                this.closeModal();
                this.verificationTokenValid = false;
            }

            this.error = result.data;
            this.otpLimitReached = result.data.status === HttpStatus.TOO_MANY_REQUESTS;

            return;
        }

        this.processing = false;
        this.failModalOpened = false;
        this.successModalOpened = true;
    }

    async resendOtp() {
        this.createStatus = OtpStatus.Pending;

        this.verificationInfo.requestTimestamp = dayjs(new Date()).valueOf();

        const tokenResult = await getToken(config.googleRecaptchaSiteKeyId);

        if (!tokenResult.success) {
            this.createStatus = OtpStatus.None;

            return;
        }

        const result = await IdentityService.requestOtp({
            username: this.verificationInfo.userName,
            countryCode: this.verificationInfo.countryCode,
            language: this.getLang(),
            intent: this.verificationInfo.intent,
            token: tokenResult.data.token
        });

        this.createStatus = OtpStatus.None;
    }
}
