export const BULGARIAN_NATIVE_LANGUAGE = 'Български';
export const CROATIAN_NATIVE_LANGUAGE = 'Hrvatski';
export const CZECH_NATIVE_LANGUAGE = 'Česky';
export const DANISH_NATIVE_LANGUAGE = 'Dansk';
export const DEUTSCH_NATIVE_LANGUAGE = 'Deutsch';
export const DUTCH_NATIVE_LANGUAGE = 'Dutch';
export const ENGLISH_NATIVE_LANGUAGE = 'English';
export const FINNISH_NATIVE_LANGUAGE = 'Suomi';
export const FRENCH_NATIVE_LANGUAGE = 'Français';
export const GREEK_NATIVE_LANGUAGE = 'Ελληνικά';
export const HUNGARIAN_NATIVE_LANGUAGE = 'Magyar';
export const ITALIAN_NATIVE_LANGUAGE = 'Italiano';
export const POLISH_NATIVE_LANGUAGE = 'Polski';
export const PORTUGUESE_NATIVE_LANGUAGE = 'Português';
export const SPANISH_NATIVE_LANGUAGE = 'Español';
export const ROMANIAN_NATIVE_LANGUAGE = 'Română';
export const SWEDISH_NATIVE_LANGUAGE = 'Svenska';
