import { GeoLocationService } from '../services';
import { GeoLocationStorage, GeoLocationLocalStorage } from '../storage';

interface GeoLocationPlugin {
    storageKey: string,
    expires?: number,
    getCountryCode: Function,
    findCountryByIp: Function,
    setCountryCode: Function
}

export interface GeoLocationPluginOptions {
    storageKey: string;
    storage?: GeoLocationStorage;
}

declare module 'vue/types/vue' {
    // Global properties can be declared
    // on the `VueConstructor` interface
    interface VueConstructor {
        $geoLocator: GeoLocationPlugin
    }
}

export default class GeoLocation {
    static install(Vue, options: GeoLocationPluginOptions) {
        Vue.$geoLocator = {
            storageKey: options.storageKey,
            storage: options.storage || new GeoLocationLocalStorage(),

            async getCountryCode(ip?: string) {
                let countryCode = this.storage.getItem(this.storageKey);

                if (!countryCode) {
                    countryCode = await this.findCountryByIp(ip);
                    this.setCountryCode(countryCode);
                }

                return countryCode;
            },

            async findCountryByIp(ip?: string) {
                const response = await GeoLocationService.findCountry(ip);

                if (response.success) {
                    return response.data;
                }

                return '';
            },

            setCountryCode(countryCode: string) {
                this.storage.setItem(this.storageKey, countryCode);
            }
        }

    }
}
