import { COUNTRY_CODES } from '@/constants/country-codes';
import { LOCALES } from './locales';

export const COUNTRY_LANGUAGE = {
    [COUNTRY_CODES.Austria]: LOCALES.Deutsch,
    [COUNTRY_CODES.Belgium]: LOCALES.French,
    [COUNTRY_CODES.Bulgarian]: LOCALES.Bulgarian,
    [COUNTRY_CODES.Croatia]: LOCALES.Croatian,
    [COUNTRY_CODES.Cyprus]: LOCALES.Greek,
    [COUNTRY_CODES.CzechRepublic]: LOCALES.Czech,
    [COUNTRY_CODES.Denmark]: LOCALES.Danish,
    [COUNTRY_CODES.Finland]: LOCALES.Finnish,
    [COUNTRY_CODES.France]: LOCALES.French,
    [COUNTRY_CODES.Germany]: LOCALES.Deutsch,
    [COUNTRY_CODES.Greek]: LOCALES.Greek,
    [COUNTRY_CODES.Hungary]: LOCALES.Hungarian,
    [COUNTRY_CODES.Italy]: LOCALES.Italian,
    [COUNTRY_CODES.Luxembourg]: LOCALES.French,
    [COUNTRY_CODES.Netherlands]: LOCALES.Dutch,
    [COUNTRY_CODES.Poland]: LOCALES.Polish,
    [COUNTRY_CODES.Portugal]: LOCALES.Portuguese,
    [COUNTRY_CODES.Romania]: LOCALES.Romanian,
    [COUNTRY_CODES.Spain]: LOCALES.Spanish,
    [COUNTRY_CODES.Sweden]: LOCALES.Swedish
};
