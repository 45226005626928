import Service from '@/core/services/service';
import api from './config';

export default {
    findCountry(ip?: string) {
        const services = new Service(api);
        const url = '/country:geolocate';
        let requestConfig = {};

        if (ip) {
            requestConfig = {
                params: {
                    ip: ip
                }
            };
        }

        return services.getData(url, requestConfig);
    }
};
