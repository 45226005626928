import { Language } from '../interfaces';
import { LOCALES } from './locales';
import * as NativeLanguages from './native-languages';

export const BULGARIAN_LANGUAGE: Language = {
    text: NativeLanguages.BULGARIAN_NATIVE_LANGUAGE,
    value: LOCALES.Bulgarian
};

export const CROATIAN_LANGUAGE: Language = {
    text: NativeLanguages.CROATIAN_NATIVE_LANGUAGE,
    value: LOCALES.Croatian
};

export const CZECH_LANGUAGE: Language = {
    text: NativeLanguages.CZECH_NATIVE_LANGUAGE,
    value: LOCALES.Czech
};

export const DANISH_LANGUAGE: Language = {
    text: NativeLanguages.DANISH_NATIVE_LANGUAGE,
    value: LOCALES.Danish
};

export const DEUTSCH_LANGUAGE: Language = {
    text: NativeLanguages.DEUTSCH_NATIVE_LANGUAGE,
    value: LOCALES.Deutsch
};

export const DUTCH_LANGUAGE: Language = {
    text: NativeLanguages.DUTCH_NATIVE_LANGUAGE,
    value: LOCALES.Dutch
};

export const ENGLISH_LANGUAGE: Language = {
    text: NativeLanguages.ENGLISH_NATIVE_LANGUAGE,
    value: LOCALES.English
};

export const FINNISH_LANGUAGE: Language = {
    text: NativeLanguages.FINNISH_NATIVE_LANGUAGE,
    value: LOCALES.Finnish
};

export const FRENCH_LANGUAGE: Language = {
    text: NativeLanguages.FRENCH_NATIVE_LANGUAGE,
    value: LOCALES.French
};

export const GREEK_LANGUAGE: Language = {
    text: NativeLanguages.GREEK_NATIVE_LANGUAGE,
    value: LOCALES.Greek
};

export const HUNGARIAN_LANGUAGE: Language = {
    text: NativeLanguages.HUNGARIAN_NATIVE_LANGUAGE,
    value: LOCALES.Hungarian
};

export const ITALIAN_LANGUAGE: Language = {
    text: NativeLanguages.ITALIAN_NATIVE_LANGUAGE,
    value: LOCALES.Italian
};

export const POLISH_LANGUAGE: Language = {
    text: NativeLanguages.POLISH_NATIVE_LANGUAGE,
    value: LOCALES.Polish
};

export const PORTUGUESE_LANGUAGE: Language = {
    text: NativeLanguages.PORTUGUESE_NATIVE_LANGUAGE,
    value: LOCALES.Portuguese
};

export const SPANISH_LANGUAGE: Language = {
    text: NativeLanguages.SPANISH_NATIVE_LANGUAGE,
    value: LOCALES.Spanish
};

export const ROMANIAN_LANGUAGE: Language = {
    text: NativeLanguages.ROMANIAN_NATIVE_LANGUAGE,
    value: LOCALES.Romanian
};

export const SWEDISH_LANGUAGE: Language = {
    text: NativeLanguages.SWEDISH_NATIVE_LANGUAGE,
    value: LOCALES.Swedish
};
